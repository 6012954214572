import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://e1669cc11cd79c3542ba5349bbc07c68@o4508132362289152.ingest.us.sentry.io/4508200010383360",
  debug: false,
  environment: "development",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  sampleRate: 0.5,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});